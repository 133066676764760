/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

import { LoadingIndicator } from "@dds/components";
import { React, useEffect } from "react";
import "./loader.scss";

// ==============================|| Loader ||============================== //

/**
 * Renders a loader component that displays a progress bar.
 *
 * @return {JSX.Element} The loader component.
 */
const Loader = () => {
  useEffect(() => {
    let element = document.getElementById("loadingindicator-809853708");
    new LoadingIndicator(element);
  }, []);

  return (
    <div className="loaderwrapper">
      <div
        className="dds__loading-indicator dds__loading-indicator--large dds__loading-indicator--centered"
        id="loadingindicator-809853708"
      >
        <div
          className="dds__loading-indicator__label dds__sr-only"
          aria-live="polite"
        >
          Loading
        </div>
        <div className="dds__loading-indicator__spinner"></div>
      </div>
    </div>
  );
};

export default Loader;
