/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

/**
 * Scrolls to the top of the page when the pathname changes.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children to render.
 * @return {React.ReactNode} The rendered children or null.
 */
const ScrollTop = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }, [pathname]);
  return children || null;
};

ScrollTop.propTypes = {
  children: PropTypes.node,
};

export default ScrollTop;
