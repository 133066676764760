/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

import { React, Suspense } from "react";

// project import
import Loader from "./Loader";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

/**
 * Creates a higher-order component that wraps a given component with a Suspense
 * fallback.
 *
 * @param {React.Component} Component - The component to be wrapped.
 * @return {React.Component} The wrapped component.
 */
const Loadable = (Component) => {
  /**
   * Creates a higher-order component that wraps a given component with a Suspense
   * fallback.
   *
   * @param {Object} props - The props to be passed to the wrapped component.
   * @return {JSX.Element} The wrapped component with a Suspense fallback.
   */
  const ComponentWithSuspense = (props) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
  ComponentWithSuspense.displayName = `Loadable(${
    Component.displayName || Component.name
  })`;
  return ComponentWithSuspense;
};

export default Loadable;
