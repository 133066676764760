/* 
************************************************************************
* DELL PROPRIETARY INFORMATION
* 
* This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
* contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
* provided by or on behalf of Dell Inc. or its subsidiaries.
*  
* Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
*  
* DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
* EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
* FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
* SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
* DERIVATIVES.
************************************************************************
*/

import { useRoutes } from 'react-router-dom';
import HomeRoutes from './HomeRoutes';
import DashboardRoutes from './DashboardRoutes';
import React from "react";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate
} from "@azure/msal-react";

// ==============================|| ROUTING RENDER ||============================== //

/**
 * DMPRoutes function is a React component that renders routes based on authentication status.
 * It uses the UnauthenticatedTemplate and AuthenticatedTemplate components to conditionally render routes.
 * The routes are defined in the HomeRoutes and DashboardRoutes objects.
 */
export default function DMPRoutes() {
  return (
    <React.Fragment>
      <UnauthenticatedTemplate>
        {useRoutes([HomeRoutes, DashboardRoutes])}
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {useRoutes([HomeRoutes, DashboardRoutes])}
      </AuthenticatedTemplate>
    </React.Fragment>
  );
}