/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

import { React } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import "./index.scss";
import Masthead from "./Header";

// ==============================|| Dashboard Layout ||============================== //

/**
 * Renders the DashboardLayout component.
 *
 * @return {JSX.Element} The rendered DashboardLayout component.
 */
const DashboardLayout = () => (
  <main className="dds__w-100">
    <Masthead />
    <div className="dashboard-layout-wrapper">
      <div className="dashboard-layout-content">
        <Outlet />
      </div>
    </div>
    <Footer />
  </main>
);

export default DashboardLayout;
