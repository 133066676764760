import {useEffect} from 'react'

function preventClick() {
  
  useEffect(() => {
    // This function prevents the default context menu (right-click menu) from appearing.
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // This line selects all anchor ('a') elements in the document.
    const links = document.querySelectorAll("a");

    // This loop adds the handleContextMenu function as an event listener for the 'contextmenu' event to each anchor element.
    // This means that when a user right-clicks on a link, the default context menu will not appear.
    if(links.length > 0){
    links.forEach((link) => {
      link.addEventListener("contextmenu", handleContextMenu);
    });
  }
    // The function passed to useEffect can optionally return a cleanup function.
    // This function will be run before the component is unmounted and before subsequent runs of the effect function.
    // In this case, the cleanup function removes the 'contextmenu' event listener from each anchor element.
    return () => {
      if(links.length > 0){
      links.forEach((link) => {
        link.removeEventListener("contextmenu", handleContextMenu);
      });
      }
    };
  }, []); // The empty array means this effect will only run once, when the component is first rendered.
}

export default preventClick