/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

import { React } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header/index";
import Footer from "layout/DashboardLayout/Footer";
import PropTypes from "prop-types";

const Main = ({ children }) => <main>{children}</main>;

// props validation
Main.propTypes = {
  children: PropTypes.node.isRequired,
};

// ==============================|| MINIMAL LAYOUT ||============================== //

/**
 * Renders the base layout component.
 *
 * @return {JSX.Element} The rendered base layout component.
 */
const BaseLayout = () => (
  <>
    <Header />
    <Main sx={{ width: "100%", p: { xs: 0, sm: 0 }, marginTop: 8 }}>
      <Outlet />
      <Footer />
    </Main>
  </>
);

export default BaseLayout;
