/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

import { React, lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "components/Loadable";
import DashboardLayout from "layout/DashboardLayout";

const DashboardHomePage = Loadable(lazy(() => import("pages/dashboard/Home")));
const DashboardDevicesPage = Loadable(
  lazy(() => import("pages/dashboard/Devices"))
);
const DashboardDevicePage = Loadable(
  lazy(() => import("pages/dashboard/Devices/pages/DeviceDetail"))
);
const DashboardAppsPage = Loadable(lazy(() => import("pages/dashboard/Apps")));
const DashboardAppDetailPage = Loadable(
  lazy(() => import("pages/dashboard/Apps/pages/AppDetailPage"))
);

// ==============================|| Dashboard Routing ||============================== //

const DashboardRoutes = {
  path: "dashboard",
  element: <DashboardLayout />,
  children: [
    {
      path: "home",
      element: <DashboardHomePage />,
    },
    {
      path: "devices",
      element: <DashboardDevicesPage />,
    },
    {
      path: "/dashboard/devices/:deviceId",
      element: <DashboardDevicePage />,
    },
    {
      path: "apps",
      element: <DashboardAppsPage />,
    },
    {
      path: "/dashboard/apps/:appId",
      element: <DashboardAppDetailPage />,
    },
    {
      path: "",
      element: <Navigate to="/dashboard/home" />,
    },
    // {
    //   path: '*',
    //   element: <Navigate to="/dashboard/home" />
    // }
  ],
};

export default DashboardRoutes;
